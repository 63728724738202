import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                <section>
                    <h3>Pour tout renseignement concernant les tarifs merci de me contacter pour un devis sur mesure : </h3>
                </section>
                <section>
                    <form method="post" action="https://formspree.io/f/xzbkylrl">
                        <div className="field half first">
                            <label htmlFor="tel">Téléphone</label>
                            <input type="tel" name="tel" id="tel" />
                        </div>
                        <div className="field half">
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" id="email" />
                        </div>
                        <div className="field">
                            <label htmlFor="message">Message</label>
                            <textarea name="message" id="message" rows="6"></textarea>
                        </div>
                        <ul className="actions">
                            <li><input type="submit" value="Envoyer le message" className="special" /></li>
                            <li><input type="reset" value="Effacer" /></li>
                        </ul>
                    </form>
                </section>
            </section>
            <section className="split">
                <section>
                    <a href="mailto:atouphoto@gmail.com">
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a>atouphoto@gmail.com</a>
                    </div>
                    </a>
                </section>
                <section>
                    <a href="tel:+33620842616">
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Téléphone</h3>
                        <span>06 20 84 26 16</span>
                    </div>
                    </a>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Adresse</h3>
                        <span>86000, Poitiers, France</span>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-arrow-circle-o-up"></span>
                        <h3>SIRET : 794 935 544</h3>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
