import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="copyright">
                <li><a href="https://vincentducos.fr/">Vincent DUCOS</a> &copy; {new Date().getFullYear()}</li>
            </ul>
        </div>


    </footer>
)

export default Footer
