import React from 'react'
import pic_biographie_1 from "../assets/images/pic_biographie_1.jpg";
import Layout from "./layout";

const Biographie = (props) => (
        <div id="main">
            <section id="biographie">
                <div className="inner">
                    <header className="major">
                        <h2>Qui suis-je ?</h2>
                    </header>
                    <p><span className="image left"><img src={pic_biographie_1} alt="" /></span>Photographe professionnel depuis plus de 15 ans sur les sujets les plus divers: immobilier, événements professionnels, photo scolaire, voyages, mariages, baptêmes, gastronomie et animaux.
                        <br/><br/>Je suis basé à Poitiers dans la Vienne (86) et mobile dans toute la France. J'ai été attiré très tôt par les métiers de la création et de l'image. Mon expérience et ma sensibilité me permettront d'immortaliser vos événements qu'ils soient professionnels, familiaux ou personnels.
                    </p>
                </div>
            </section>
            <section id="covid">
                <div className="inner">
                    <div className="box">
                        <header className="major">
                            <h3>COVID 19</h3>
                        </header>
                        <p>
                            Les mesures sanitaires en vigueur seront respectées pour garantir la sécurité de tous, selon les consignes de la F.F.P.M.I. (Fédération Française des Professionnels des Métiers de l’Image).
                        </p>
                    </div>
                </div>
            </section>
        </div>
)

export default Biographie
